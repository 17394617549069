import { LockClosedIcon } from '@heroicons/react/solid';
import format from 'date-fns/format';
import { CSSProperties } from 'react';

import {
  BeehiivIssue,
  GhostIssue,
  PodcastEpisode,
  ProjectSettings,
  RevueIssue,
  RSSIssue,
  SubstackIssue,
} from '../types';
import Logo from './Icons/Logo';

type Props = {
  projectSettings: ProjectSettings;
  issues: SubstackIssue[] | RevueIssue[] | BeehiivIssue[] | GhostIssue[] | RSSIssue[];
  isFrame?: boolean;
  className?: string;
  isLoading?: boolean;
};

const renderDate = (dateString: string, dateFormat?: string) => {
  if (dateFormat) {
    try {
      const renderedDate = format(new Date(dateString), dateFormat);
      return renderedDate;
    } catch (e) {
      return format(new Date(dateString), 'MMM dd');
    }
  }
  return format(new Date(dateString), 'MMM dd');
};

const SubstackCard = ({
  issue: { url, imgUrl, author, description, dateString, title, privateIssue },
  projectSettings,
  isFrame,
}: {
  issue: SubstackIssue;
  projectSettings: ProjectSettings;
  isFrame?: boolean;
}) => (
  <a
    href={isFrame ? url : '#'}
    className=""
    target={isFrame ? '_blank' : '_self'}
    rel="noopener noreferrer"
  >
    <div className="sb-preview-item substack" data-paid-post={privateIssue}>
      {projectSettings.showImage && imgUrl && (
        <div className="sb-preview-image" style={{ backgroundImage: `url(${imgUrl})` }} />
      )}
      <div className="sb-preview-content-wrap">
        <div className="sb-preview-content">
          <div className="sb-preview-item-title">{title}</div>
          {description && <p className="sb-preview-item-description">{description.trim()}</p>}
        </div>
        {(projectSettings.showAuthor || projectSettings.showDate || privateIssue) && (
          <div className="sb-preview-author-date-wrap">
            {projectSettings.showAuthor && author && (
              <div className="sb-preview-author">{author}</div>
            )}
            <div className="sb-preview-last-row">
              {privateIssue && <LockClosedIcon className="w-4 h-4" />}
              {projectSettings.showDate && dateString && (
                <div className="sb-preview-date">
                  {renderDate(dateString, projectSettings.basicStyleConfig?.dateFormat)}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  </a>
);

const RSSCard = ({
  className,
  issue: { url, imgUrl, author, description, dateString, title },
  projectSettings,
  isFrame,
}: {
  className?: string;
  issue: GhostIssue | RSSIssue | BeehiivIssue;
  projectSettings: ProjectSettings;
  isFrame?: boolean;
}) => (
  <a
    href={isFrame ? url : '#'}
    className=""
    target={isFrame ? '_blank' : '_self'}
    rel="noopener noreferrer"
  >
    <div className={`sb-preview-item ${className}`}>
      {projectSettings.showImage && imgUrl && (
        <div className="sb-preview-image" style={{ backgroundImage: `url(${imgUrl})` }} />
      )}
      <div className="sb-preview-content-wrap">
        <div className="sb-preview-content">
          <div className="sb-preview-item-title">{title}</div>
          {description && (
            <p
              className="sb-preview-item-description"
              dangerouslySetInnerHTML={{ __html: description.trim() }}
            />
          )}
        </div>
        {(projectSettings.showAuthor || projectSettings.showDate) && (
          <div className="sb-preview-author-date-wrap">
            {projectSettings.showAuthor && author && (
              <span className="sb-preview-author">{author}</span>
            )}
            {projectSettings.showAuthor && projectSettings.showDate && <>&nbsp;/&nbsp;</>}
            {projectSettings.showDate && dateString && (
              <span className="sb-preview-date">
                {renderDate(dateString, projectSettings.basicStyleConfig?.dateFormat)}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  </a>
);

const PodcastCard = ({
  issue: { url, imgUrl, author, description, dateString, title },
  projectSettings,
  isFrame,
}: {
  issue: PodcastEpisode;
  projectSettings: ProjectSettings;
  isFrame?: boolean;
}) => (
  <a
    href={isFrame ? url : '#'}
    className=""
    target={isFrame ? '_blank' : '_self'}
    rel="noopener noreferrer"
  >
    <div className={`sb-preview-item podcast`}>
      {projectSettings.showImage && imgUrl && (
        <div className="sb-preview-image" style={{ backgroundImage: `url(${imgUrl})` }} />
      )}
      <div className="sb-preview-content-wrap">
        <div className="sb-preview-content">
          <div className="sb-preview-item-title">{title}</div>
          {description && (
            <p
              className="sb-preview-item-description"
              dangerouslySetInnerHTML={{ __html: description.trim() }}
            />
          )}
        </div>
        {(projectSettings.showAuthor || projectSettings.showDate) && (
          <div className="sb-preview-author-date-wrap">
            {/* TODO factor this out for every card */}
            {(() => {
              const itemsToShow = [];
              if (projectSettings.showAuthor && author) {
                itemsToShow.push(
                  <span key={`${title}-${author}`} className="sb-preview-author">
                    {author}
                  </span>
                );
                itemsToShow.push(<>&nbsp;/&nbsp;</>);
              }
              if (projectSettings.showDate && dateString) {
                itemsToShow.push(
                  <span key={`${title}-${dateString}`} className="sb-preview-date">
                    {renderDate(dateString, projectSettings.basicStyleConfig?.dateFormat)}
                  </span>
                );
                itemsToShow.push(<>&nbsp;/&nbsp;</>);
              }
              itemsToShow.pop();

              return itemsToShow;
            })()}
          </div>
        )}
      </div>
    </div>
  </a>
);

const RevueCard = ({
  issue: { url, description, dateString, title },
  projectSettings,
  isFrame,
}: {
  issue: RevueIssue;
  projectSettings: ProjectSettings;
  isFrame?: boolean;
}) => (
  <a
    href={isFrame ? url : '#'}
    className=""
    target={isFrame ? '_blank' : '_self'}
    rel="noopener noreferrer"
  >
    <div className="sb-preview-item revue">
      <div className="sb-preview-content-wrap">
        <div className="sb-preview-content">
          <div className="sb-preview-item-title">{title}</div>
          {description && (
            <div
              className="sb-preview-item-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
        {(projectSettings.showAuthor || projectSettings.showDate) && (
          <div className="sb-preview-author-date-wrap">
            {projectSettings.showDate && dateString && (
              <div className="sb-preview-date">
                {' '}
                {renderDate(dateString, projectSettings.basicStyleConfig?.dateFormat)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  </a>
);

const Preview = ({ projectSettings, issues, isFrame, className = '', isLoading }: Props) => {
  const issuesToShow = issues.slice(0, projectSettings.numberOfPostsToShow);
  const hideBranding =
    projectSettings.basicStyleConfig && 'hideBranding' in projectSettings.basicStyleConfig
      ? projectSettings.basicStyleConfig.hideBranding
      : false;
  return (
    <>
      <div
        className={`sb-preview-wrapper ${className}`}
        style={
          projectSettings.basicStyleConfig
            ? ({
                '--sb-text': projectSettings.basicStyleConfig.textColor,
                '--sb-background': projectSettings.basicStyleConfig.bgColor,
                '--sb-font': `'${projectSettings.basicStyleConfig.fontName}'`,
              } as CSSProperties) // sorry react
            : undefined
        }
      >
        {projectSettings.newsletterType === 'substack' &&
          issuesToShow.map((issue, index) => (
            <SubstackCard
              key={issue.url + index}
              issue={issue as SubstackIssue}
              projectSettings={projectSettings}
              isFrame={isFrame}
            />
          ))}
        {projectSettings.newsletterType === 'beehiiv' &&
          issuesToShow.map((issue, index) => (
            <RSSCard
              className="beehiiv"
              key={issue.url + index}
              issue={issue as BeehiivIssue}
              projectSettings={projectSettings}
              isFrame={isFrame}
            />
          ))}
        {projectSettings.newsletterType === 'ghost' &&
          issuesToShow.map((issue, index) => (
            <RSSCard
              className="ghost"
              key={issue.url + index}
              issue={issue as GhostIssue}
              projectSettings={projectSettings}
              isFrame={isFrame}
            />
          ))}
        {projectSettings.newsletterType === 'rss' &&
          issuesToShow.map((issue, index) => (
            <RSSCard
              className="rss"
              key={issue.url + index}
              issue={issue as RSSIssue}
              projectSettings={projectSettings}
              isFrame={isFrame}
            />
          ))}
        {projectSettings.newsletterType === 'revue' &&
          issuesToShow.map((issue, index) => (
            <RevueCard
              key={issue.url + index}
              issue={issue as RevueIssue}
              projectSettings={projectSettings}
              isFrame={isFrame}
            />
          ))}
        {projectSettings.newsletterType === 'podcast' &&
          issuesToShow.map((issue) => (
            <PodcastCard
              key={issue.url}
              issue={issue as PodcastEpisode}
              projectSettings={projectSettings}
              isFrame={isFrame}
            />
          ))}

        {issues.length === 0 &&
          isLoading &&
          (() => {
            const section = projectSettings.newsletterSettings?.section;
            const newsletter = projectSettings.newsletterURL;
            return (
              <div className="flex items-center justify-center w-full text-center">
                Loading preview for {section ? `${section} section of` : ``} {newsletter} ...
              </div>
            );
          })()}
        {issues.length === 0 &&
          !isLoading &&
          (() => {
            const section = projectSettings.newsletterSettings?.section;
            const newsletter = projectSettings.newsletterURL;
            return (
              <div className="flex items-center justify-center w-full text-center">
                Could not get any posts for {section ? `${section} section of` : ``} {newsletter}
              </div>
            );
          })()}
      </div>
      {issues.length > 0 && !hideBranding && (
        <div
          style={
            projectSettings.basicStyleConfig
              ? ({
                  '--sb-text': projectSettings.basicStyleConfig.textColor,
                } as CSSProperties) // sorry react
              : undefined
          }
          className="w-full text-xs bg-sb-background text-sb-text opacity-70 font-serif flex justify-end pr-2"
        >
          <a
            href={`https://stackblocks.app?utm_source=${projectSettings.newsletterURL}`}
            className="flex items-center text-xs font-serif hover:underline focus:underline"
            target="_blank"
          >
            <span className="">Powered by&nbsp;</span>
            <Logo className="w-4 h-4 inline" />
            <span className="">&nbsp;Stackblocks</span>
          </a>
        </div>
      )}
    </>
  );
};

export default Preview;
