import { NotionAPI } from 'notion-client';
import { getPageProperty } from 'notion-utils';
// import toast from 'react-hot-toast';

import { PaidPlan, Project } from './graphql/types.generated';
import { ProjectSettings, BasicStyleConfig, NewsletterSettings, SubstackSettings } from './types';

// TODO add userid so that it is easy to embed multiple stackblocks
export const getHTMLEmbedCode = (projectId: string) =>
  `<div class="stackblocks-embed" data-stackblock="${projectId}"></div>
<script src="https://frame.stackblocks.app/frame.js"></script>`;

export const getIframeURL = (projectId: string) => `https://frame.stackblocks.app/${projectId}`;

export const defaultBasicStyleConfig = {
  fontName: '',
  bgColor: '#fff',
  textColor: '#1a1a1a',
  hideBranding: false,
} as const;

export const decodeSettings = (projectSettings: Omit<Project, 'users'>): ProjectSettings => {
  const { basicStyleConfig, newsletterSettings, ...currentProject } = projectSettings;
  return {
    ...currentProject,
    basicStyleConfig: basicStyleConfig
      ? (JSON.parse(basicStyleConfig || '') as BasicStyleConfig)
      : null,
    newsletterSettings: newsletterSettings
      ? (JSON.parse(newsletterSettings || '') as NewsletterSettings)
      : null,
  };
};

export const encodeSettings = (projectSettings: ProjectSettings): Omit<Project, 'users'> => {
  const { basicStyleConfig, newsletterSettings, ...currentProject } = projectSettings;
  return {
    ...currentProject,
    basicStyleConfig: basicStyleConfig ? JSON.stringify(basicStyleConfig || {}) : undefined,
    newsletterSettings: newsletterSettings ? JSON.stringify(newsletterSettings || {}) : undefined,
  };
};

export const googleFontNames = [
  'Alegreya',
  'Alegreya Sans',
  'Arvo',
  'Bitter',
  'Cabin',
  'Copse',
  'Corben',
  'Cormorant Garamond',
  'Courier Prime',
  'Crimson Text',
  'Cutive',
  'Darker Grotesque',
  'DM Sans',
  'DM Serif Display',
  'Eczar',
  'Epilogue',
  'Gentium Basic',
  'IBM Plex Mono',
  'IBM Plex Sans',
  'Inconsolata',
  'Inter',
  'Kumbh Sans',
  'Lato',
  'Libre Baskerville',
  'Libre Franklin',
  'Literata',
  'Lora',
  'Manrope',
  'Merriweather',
  'Montserrat',
  'Nanum Gothic',
  'Nanum Myeongjo',
  'Neuton',
  'Noto Sans',
  'Noto Sans HK',
  'Noto Sans JP',
  'Noto Sans KR',
  'Noto Sans SC',
  'Noto Sans TC',
  'Nunito',
  'Oswald',
  'Open Sans',
  'Pacifico',
  'Playfair',
  'Playfair Display',
  'Poppins',
  'Proza Libre',
  'Quattrocento',
  'Quicksand',
  'Red Hat Display',
  'Roboto',
  'Roboto Mono',
  'Rubik',
  'Sanchez',
  'Scope One',
  'Slabo 13px',
  'Syne',
  'Sora',
  'Space Grotesk',
  'Space Mono',
  'Taviraj',
  'Trirong',
  'Trocchi',
  'Vesper Libre',
  'Vidaloka',
  'VT323',
  'Work Sans',
] as const;

export const substackSortOptions: { label: string; value: SubstackSettings['sortOption'] }[] = [
  {
    label: 'Latest',
    value: 'new',
  },
  {
    label: 'Top',
    value: 'top',
  },
];

export const defaultSubstackSettings: SubstackSettings = {
  sortOption: 'new',
  showOnlyPaid: false,
};

export const allowedCustomization: Record<ProjectSettings['newsletterType'], string[]> = {
  substack: [
    'numberOfPostsToShow',
    'showImage',
    'showDate',
    'showAuthor',
    'sortOption',
    'showOnlyPaid',
  ],
  revue: ['numberOfPostsToShow', 'showDate'],
  beehiiv: ['numberOfPostsToShow', 'showImage', 'showDate', 'showAuthor'],
  ghost: ['numberOfPostsToShow', 'showImage', 'showDate', 'showAuthor'],
  podcast: ['numberOfPostsToShow', 'showImage', 'showDate', 'showAuthor'],
  rss: ['numberOfPostsToShow', 'showImage', 'showDate', 'showAuthor'],
};

export const getFriendlyUrl = (url?: string) => {
  if (!url) return null;
  try {
    const name = new URL(url).hostname;
    return name;
  } catch (er) {
    return null;
  }
};

export const getAppUrl = () => {
  if (process.env.NODE_ENV === `development`) {
    return 'http://127.0.0.1:3000';
  }
  return 'https://stackblocks.app';
};

const allowedProjectsMap: Record<PaidPlan, number> = {
  pro: 3,
  ltd: 8,
  cancelled: 1,
  grandfathered: 1,
  freeTrail: 3,
  staff: 99,
};

export const getAllowedProjects = (plan: PaidPlan, email?: string | null) => {
  if (email?.endsWith('balla.dev')) return 999;
  if (email === 'axj773@alumni.bham.ac.uk') return 6;

  return allowedProjectsMap[plan];
};
